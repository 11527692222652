import styled from "styled-components";
import { PHONE, TAB_LAND, TAB_PORT } from "../../constants/BreakpointConstants";
import {WHITE_COLOR, ORANGE_COLOR, DARK_ORANGE_COLOR} from "../../constants/ColorConstants";

export const StyledSection = styled.section`
    background-color: ${ORANGE_COLOR};
    background-image: linear-gradient(
    135deg,
    ${DARK_ORANGE_COLOR} 0%,
    ${ORANGE_COLOR} 100%
    );
    color: ${WHITE_COLOR};
    height: 100%;
    border-top: 0px;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
    padding-bottom: 10%;
    font-family: 'Noto Sans JP', sans-serif;

    @media (max-width: ${TAB_LAND}) {
        height: 100%;
        -webkit-clip-path: none;
        clip-path: none;
      }

    .about-wrapper {
        @media (max-width: ${PHONE}) {
            padding-bottom: 6rem;
          }

        &__image {
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: center;

            @media (max-width: ${TAB_PORT}) {
                padding-bottom: 6.4rem;
              }

            @media (max-width: ${TAB_LAND}) {
                height: 100%;
              }
        }

        &__info {
            display: flex;
            height: 100%;
            justify-content: center;
            flex-direction: column;

            
            @media (max-width: ${PHONE}) {
                align-items: center;
            }
            
            &-text {
                font-size: 1.6rem;
                text-align: left;

                @media (max-width: ${TAB_PORT}) {
                    font-size: 1.5rem;
                    text-align: center;
                  }
                @media (max-width: ${PHONE}) {
                    font-size: 1.6rem;
                    text-align: center;
                  }
            }
        }
    }
`;