import styled from "styled-components"
import { PHONE, TAB_LAND } from "../../constants/BreakpointConstants"
import {
  WHITE_COLOR,
  ORANGE_COLOR,
  DARK_ORANGE_COLOR,
} from "../../constants/ColorConstants"

export const StyledSection = styled.section`
    background-image: linear-gradient(135deg, ${ORANGE_COLOR} 0%, ${DARK_ORANGE_COLOR} 100%);
    -webkit-clip-path: polygon(0 15vh, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 15vh, 100% 0, 100% 100%, 0 100%);
    padding: 15rem 0 10rem 0;
    margin-top: -15rem;
    margin-bottom: -1px;
    color: ${WHITE_COLOR};

    @media (max-width: ${TAB_LAND}) {
      padding: 10rem 0;
      clip-path: none;
      margin-top: 0;
      -webkit-clip-path: none;
    }

    .contact-wrapper {
        margin-top: 3.2rem;
        padding: 0 2rem;
        backface-visibility: hidden;
    
        &__text {
          margin-bottom: 2.5rem;
        }
    
        &__text,
        a {
          font-size: 2.4rem;

          @media (max-width: ${PHONE}) {
            font-size: 2rem;
          }
        }
`
