import React from "react"
import { StyledFooter } from "./FooterStylesheet"
import { Container } from "react-bootstrap"
import { Link } from "react-scroll"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronUp } from "@fortawesome/free-solid-svg-icons"
import {
  faTwitter,
  faTwitch,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons"
import { OutboundLink } from "gatsby-plugin-google-gtag"

const Footer = () => {
  return (
    <StyledFooter id="footer navbar-static-bottom">
      <Container>
        <Link to="header" smooth duration={1000}>
          <span className="back-to-top">
            <FontAwesomeIcon icon={faChevronUp} size="2x" />
          </span>
        </Link>
        <div class="social-links">
          <OutboundLink
            href="https://twitter.com/Yaysian"
            rel="noopener noreferrer"
            target="_blank"
            aria-label="twitter"
          >
            <FontAwesomeIcon icon={faTwitter} inverse />
          </OutboundLink>
          <OutboundLink
            href="https://twitch.tv/yaysian/"
            rel="noopener noreferrer"
            target="_blank"
            aria-label="twitch"
          >
            <FontAwesomeIcon icon={faTwitch} inverse />
          </OutboundLink>
          <OutboundLink
            href="https://www.youtube.com/user/fgcyaysian"
            rel="noopener noreferrer"
            target="_blank"
            aria-label="youtube"
          >
            <FontAwesomeIcon icon={faYoutube} inverse />
          </OutboundLink>
        </div>
        <hr />
        <p className="text">© {new Date().getFullYear()} Yaysian</p>
      </Container>
    </StyledFooter>
  )
}

export default Footer
