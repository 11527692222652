import styled from "styled-components"
import { TAB_LAND } from "../../constants/BreakpointConstants";
import { WHITE_COLOR } from "../../constants/ColorConstants"

export const StyledSection = styled.section`
  background-color: ${WHITE_COLOR};
  margin-top: -15rem;
  padding-top: 15rem;

  @media (max-width: ${TAB_LAND}) {
    margin-top: 0;
    padding-top: 5rem;
  }
`;
