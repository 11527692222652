import React from "react"
import { StyledSection } from "./EventsStylesheet"
import { Container, Row, Col } from "react-bootstrap"
import PropTypes from "prop-types"
import { nanoid } from "nanoid"
import { OutboundLink } from "gatsby-plugin-google-gtag"

const EVENTS = [
  {
    name: "Dreamhack Beyond",
    games: [
      {
        name: "Guilty Gear -Strive- Pools to Top 16",
        subtitle: "July 24th, 2021",
        commentators: [
          {
            name: "Proxy",
            twitter: "ProXYTCG"
          }
        ],
        url: "https://www.twitch.tv/videos/1096839765"
      }
    ]
  },
  {
    name: "DreamHack's Community Clash",
    games: [
      {
        name: "Guilty Gear -Strive- Pools to Top 8",
        subtitle: "June 30, 2021",
        commentators: [
          {
            name: "Proxy",
            twitter: "ProXYTCG",
          },
        ],
        url: "https://www.twitch.tv/videos/1071734103",
      },
    ],
  },
  {
    name: "TNS Atlanta",
    games: [
      {
        name: "Ultimate Marvel vs. Capcom 3 Pools",
        subtitle: "June 5, 2021",
        commentators: [
          {
            name: "Olympiq",
            twitter: "Olympiq_",
          },
          {
            name: "General Reaction",
            twitter: "GeneralReaction",
          },
        ],
        url:
          "https://youtube.com/playlist?list=PLitHiMi4rmZ02heG4eIDV-O9rM-nzXOzu",
      },
      {
        name: "Ultimate Marvel vs. Capcom 3 Top 24",
        subtitle: "June 5, 2021",
        commentators: [
          {
            name: "Proxy",
            twitter: "ProXYTCG",
          },
        ],
        url: "https://www.youtube.com/watch?v=j6OoKO2vV4U",
      },
      {
        name: "Ultimate Marvel vs. Capcom 3 Top 3",
        subtitle: "June 5, 2021",
        commentators: [
          {
            name: "Proxy",
            twitter: "ProXYTCG",
          },
        ],
        url: "https://www.youtube.com/watch?v=VZ_IZV5gAow&t=3879s",
      },
    ],
  },
  {
    name: "Frosty Faustings Online XIII 2021",
    games: [
      {
        name: "Ultimate Marvel vs. Capcom 3 Top 16",
        subtitle: "January 29, 2021",
        commentators: [
          {
            name: "TuboWare",
            twitter: "TuboWare",
          },
        ],
        url: "https://www.youtube.com/watch?v=yjzk2y7P2AI",
      },
    ],
  },
  {
    name: "CEOtaku 2019",
    games: [
      {
        name: "Under Night In-Birth Exe:Late[st] Top 16",
        subtitle: "September 29, 2019",
        commentators: [
          {
            name: "RafyToro",
            twitter: "RafyToro",
          },
        ],
        url:
          "https://www.youtube.com/watch?v=RLh-wtYs8fM&list=PLitHiMi4rmZ3VbohuhND6a_Sr7S081ysS",
      },
      {
        name: "Under Night In-Birth Exe:Late[st] Top 48",
        subtitle: "September 29, 2019",
        commentators: [
          {
            name: "Tari",
            twitter: "tarisbox",
          },
        ],
        url: "",
      },
      {
        name: "Ultimate Marvel vs. Capcom 3 Top 8",
        subtitle: "September 28, 2019",
        commentators: [
          {
            name: "Olympiq",
            twitter: "OlympiqFGC",
          },
          {
            name: "NinjaNam",
            twitter: "NinjaNam15",
          },
          {
            name: "Charlemagne",
            twitter: "TPOCharlemagne",
          },
        ],
        url: "https://www.youtube.com/watch?v=GckiVpfKQts",
      },
      {
        name: "Sailor Moon S Top 8",
        subtitle: "September 28, 2019",
        commentators: [
          {
            name: "Ryyudo",
            twitter: "Ryyudo",
          },
        ],
        url: "https://www.youtube.com/watch?v=UNrJUBxUmQ8",
      },
    ],
  },
  {
    name: "Red Bull Conquest Orlando 2019",
    games: [
      {
        name: "Under Night In-Birth Exe:Late[st] Top 8",
        subtitle: "August 24, 2019",
        commentators: [
          {
            name: "Persia",
            twitter: "persia_xo",
          },
          {
            name: "RafyToro",
            twitter: "RafyToro",
          },
        ],
        url: "https://www.twitch.tv/videos/471744315?t=0h21m51s",
      },
    ],
  },
  {
    name: "CEO 2019",
    games: [
      {
        name: "Under Night In-Birth Exe:Late[st] Top 8",
        subtitle: "June 30, 2019",
        url: "https://www.youtube.com/watch?v=4zhUvAXHKpc",
        commentators: [
          {
            name: "MiniMatt",
            twitter: "MiniMatt_",
          },
        ],
      },
      {
        name: "Under Night In-Birth Exe:Late[st] Day 2 Pools to Top 24",
        subtitle: "June 29, 2019",
        commentators: [
          {
            name: "Cookie",
            twitter: "CookieFGC",
          },
          {
            name: "Ryan Hunter",
            twitter: "OSRyanHunter",
          },
        ],
        url: "https://www.youtube.com/watch?v=OsivDEfjH2g",
      },
      {
        name: "Under Night In-Birth Exe:Late[st] Day 1 Pools",
        subtitle: "June 28, 2019",
        commentators: [
          {
            name: "Cookie",
            twitter: "CookieFGC",
          },
          {
            name: "Zero",
            twitter: "ZeroSyndicate",
          },
        ],
        url:
          "https://www.youtube.com/watch?v=VxtmmlbCkQg&list=PLitHiMi4rmZ3pQhlrzvhw7QPsMFeXEON4",
      },
    ],
  },
  {
    name: "CEOtaku 2018",
    games: [
      {
        name: "Sailor Moon S Top 8",
        subtitle: "September 23, 2018",
        commentators: [
          {
            name: "ScrawtVermillion",
            twitter: "ScrawtV",
          },
        ],
        url: "https://www.youtube.com/watch?v=noBT1UwOJm0",
      },
      {
        name: "Under Night In-Birth Exe:Late[st] Top 16",
        subtitle: "September 23, 2018",
        commentators: [
          {
            name: "Drunk Suika Ibuki",
            twitter: "VanDrunkSuika",
          },
        ],
        url:
          "https://www.youtube.com/watch?v=M2Ef0A1aQ94&list=PLitHiMi4rmZ3m2RVZeb7GXNBXVU8bHXtu",
      },
      {
        name: "Marvel vs. Capcom Infinite Top 8",
        subtitle: "September 22, 2018",
        commentators: [
          {
            name: "GDX",
            twitter: "TioGDX",
          },
        ],
        url:
          "https://www.youtube.com/watch?v=Slp0ImcxWE8&list=PL3I3CYybkbFHDIoc5b6vi_S8J-y6BXjom",
      },
      {
        name: "Under Night In-Birth Exe:Late[st] Top 48",
        subtitle: "September 22, 2018",
        commentators: [
          {
            name: "RaphTx",
            twitter: "RaphTx",
          },
        ],
        url:
          "https://www.youtube.com/watch?v=NWT6QuRh1FQ&list=PLitHiMi4rmZ1IgTVOWu4qfyUx8xL0Yzei",
      },
      {
        name: "Under Night In-Birth Exe:Late[st] Pools",
        subtitle: "September 22, 2018",
        commentators: [
          {
            name: "Cookie",
            twitter: "CookieFGC",
          },
        ],
        url:
          "https://www.youtube.com/watch?v=C-mqB-i1y6I&list=PLitHiMi4rmZ3sI12l8rBl7hAX_izxHqmk",
      },
    ],
  },
  {
    name: "Red Bull Conquest Orlando 2018",
    games: [
      {
        name: "Guilty Gear Xrd REV 2 Top 8",
        subtitle: "August 11, 2018",
        commentators: [
          {
            name: "Cookie",
            twitter: "CookieFGC",
          },
        ],
        url: "https://www.twitch.tv/videos/295979696",
      },
    ],
  },
  {
    name: "CEO 2018",
    games: [
      {
        name: "Marvel vs. Capcom Infinite Top 8",
        subtitle: "June 30, 2018",
        commentators: [
          {
            name: "Tong",
            twitter: "TongNeverSleeps",
          },
        ],
        url: "https://www.youtube.com/watch?v=dmNzaXfoogA",
      },
    ],
  },
]

const getTwitterURL = name => {
  return "https://twitter.com/" + name
}

const Event = ({ name, games }) => {
  return (
    <Col lg={6} sm={12}>
      <div className="event-wrapper__text">
        <h3 className="event-wrapper__text-title">{name}</h3>
        {games.map(game => {
          return (
            <div>
              <p className="event-wrapper__text-subtitle">{game.name}</p>
              <p>
                {game.subtitle} {game.commentators[0].name ? "with" : ""}
                {game.commentators
                  ? game.commentators.map((commentator, count, array) => {
                      return (
                        <OutboundLink
                          href={getTwitterURL(commentator.twitter)}
                          target="_blank"
                          aria-label="Twitter URL"
                          rel="noopener noreferrer"
                          className="cta-btn text-color-main commentator"
                        >
                          {commentator.name}
                          {count !== array.length - 1 ? "," : ""}
                        </OutboundLink>
                      )
                    })
                  : ""}
              </p>
              {game.url !== "" ? (
                <OutboundLink
                  target="_blank"
                  rel="noopener noreferrer"
                  className="cta-btn cta-btn--header"
                  href={game.url}
                >
                  Go to VOD
                </OutboundLink>
              ) : (
                ""
              )}
            </div>
          )
        })}
      </div>
    </Col>
  )
}

Event.propTypes = {
  name: PropTypes.string,
  games: PropTypes.array,
}

const Events = () => {
  const getRows = () => {
    let rows = []
    let row = []

    for (let i = 0; i < EVENTS.length; i++) {
      row.push(EVENTS[i])
      if (i !== 0 && i % 2 !== 0) {
        rows.push(row)
        row = []
      } else if (i === EVENTS.length - 1 && i % 2 === 0) {
        row.push({
          name: "",
          games: [
            {
              name: "",
              subtitle: "",
              commentators: [
                {
                  name: "",
                  twitter: "",
                },
              ],
              url: "",
            },
          ],
        })
        rows.push(row)
      }
    }

    return rows
  }

  return (
    <StyledSection id="events">
      <div className="event-wrapper">
        <h2
          className="section-title"
          data-sal="fade"
          data-sal-delay="300"
          data-sal-easing="ease"
          data-sal-duration="1000"
        >
          Commentary Experience
        </h2>
        <Container>
          {getRows().map(row => (
            <Row
              data-sal="slide-up"
              data-sal-delay="500"
              data-sal-easing="ease"
              data-sal-duration="1000"
            >
              {row.map(event => (
                <Event name={event.name} games={event.games} key={nanoid()} />
              ))}
            </Row>
          ))}
        </Container>
      </div>
    </StyledSection>
  )
}

export default Events
