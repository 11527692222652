import React from "react"
import { StyledSection } from "./ReelStylesheet"
import { Container, ResponsiveEmbed } from "react-bootstrap"

const Reel = () => {
  return (
    <StyledSection id="reel">
      <h2
        className="section-title"
        data-sal="fade"
        data-sal-delay="300"
        data-sal-easing="ease"
        data-sal-duration="1000"
      >
        Commentary Reel
      </h2>
      <Container
        data-sal="slide-up"
        data-sal-delay="500"
        data-sal-easing="ease"
        data-sal-duration="1000"
      >
        <ResponsiveEmbed aspectRatio="16by9">
          <iframe
            title="Yaysian Commentary Reel 2020"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/MmIMf3EMIrc"
            frameBorder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullscreen
          ></iframe>
        </ResponsiveEmbed>
      </Container>
    </StyledSection>
  )
}

export default Reel
