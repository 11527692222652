import styled from "styled-components"
import { WHITE_COLOR, DARK_GRAY_COLOR } from "../../constants/ColorConstants"

export const StyledFooter = styled.footer`
  background-color: ${DARK_GRAY_COLOR};
  color: ${WHITE_COLOR};
  padding: 4.8rem 0;
  
  .back-to-top svg{
    color: ${WHITE_COLOR};
    margin: 1rem 0 1.6rem;
    transition: all 0.2s ease-in;
    cursor: pointer;
  
    &:hover {
      transform: translateY(-2px);
    }
  }

  .social-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
  
    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: ${WHITE_COLOR};
      font-size: 3rem;
      width: 5rem;
      height: 5rem;
      margin: 1.6rem 1.6rem;
      transition: all 0.2s ease-in;
  
      &:hover {
        transform: translateY(-2px);
      }
    }
  }

  .text {
    color: grey;
    font-size: 1.3rem;

    a {
      color: grey;
      font-size: 1.3rem;
      transition: all 0.4s;
      display: inline-block;
      background-color: ${DARK_GRAY_COLOR};

      &:hover,
      &:active {
        color: $primary-color;
        -webkit-box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
        box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
      }
    }
  }

  hr {
	margin-top: 1rem;
	margin-bottom: 1rem;
	border: 0;
	width: 50%;
	border-top: 2px solid hsla(0,0%,100%,.1);
}
  `;