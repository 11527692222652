import styled from "styled-components"
import {
  WHITE_COLOR,
  ORANGE_COLOR,
  DARK_ORANGE_COLOR,
} from "../../constants/ColorConstants"
import { TAB_LAND } from "../../constants/BreakpointConstants"

export const StyledSection = styled.section`
  background-color: ${ORANGE_COLOR};
  background-image: linear-gradient(
    135deg,
    ${DARK_ORANGE_COLOR} 0%,
    ${ORANGE_COLOR} 100%
  );
  color: ${WHITE_COLOR};
  height: 100%;
  border-top: 0px;
  -webkit-clip-path: polygon(0 20%, 100% 0, 100% 80%, 0 100%);
  clip-path: polygon(0 20%, 100% 0, 100% 80%, 0 100%);
  padding-bottom: 10%;

  @media (max-width: ${TAB_LAND}) {
    height: 100%;
    -webkit-clip-path: none;
    clip-path: none;
  }

  .section-title {
    padding: 6rem 0 0;

    @media (max-width: ${TAB_LAND}) {
      padding: 0;
    }
  }

  img {
    -webkit-filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
    filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
  }

  .gatsby-image-wrapper {
    overflow: visible !important;
  }

  .row {
    align-items: center;
    height: 100%;
  }
`
