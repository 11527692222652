import React from "react"
import Header from "./Header/Header"
import About from "./About/About"
import styled from "styled-components"
import Reel from "./Reel/Reel"
import Games from "./Games/Games"
import SEO from "./SEO/SEO"
import Events from "./Events/Events"
import Contact from "./Contact/Contact"
import Footer from "./Footer/Footer"

const MainDiv = styled.div``

function App() {
  return (
    <MainDiv>
      <SEO title="Commentary Portfolio" />
      <Header />
      <About />
      <Reel />
      <Games />
      <Events />
      <Contact />
      <Footer />
    </MainDiv>
  )
}

export default App
