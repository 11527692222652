import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { StyledSection } from "./AboutStylesheet"
import Image from "../Image/Image"

const About = () => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsMobile(false)
    } else {
      setIsMobile(true)
    }
  }, [])

  return (
    <StyledSection id="about">
      <Container>
        <h2
          className="section-title"
          data-sal="fade"
          data-sal-delay="300"
          data-sal-easing="ease"
          data-sal-duration="1000"
        >
          About Me
        </h2>
        <Row className="about-wrapper">
          <Col md={6} sm={12}>
            <div
              className="about-wrapper__image"
              data-sal="slide-up"
              data-sal-delay="600"
              data-sal-easing="ease"
              data-sal-duration="1000"
            >
              <Image filename={"about.webp"} alt="" />
            </div>
          </Col>
          <Col md={6} sm={12}>
            <div
              className="about-wrapper__info"
              data-sal={isMobile ? "slide-up" : "slide-left"}
              data-sal-delay="1000"
              data-sal-easing="ease"
              data-sal-duration="1000"
            >
              <p className="about-wrapper__info-text">
                Hey! My name is Josh Cleto, also known as "Yaysian". I am an
                Orlando-based fighting game commentator with a love for
                theatrics, drawing on the forces of hype and telling a great
                story.
              </p>
              <p className="about-wrapper__info-text">
                I started out in the Fighting Game community back in 2009 with
                Street Fighter IV, but didn't really start seriously competing
                until the release of Marvel vs. Capcom 3. After that I was
                hooked, each event and each game packed to the brim with hype
                and heartbreak.
              </p>
              <p className="about-wrapper__info-text">
                I eventually landed on a mic during a local weekly event and the
                rest is history! Commentary became a way for me to unite two of
                my favorite passions, performing for an audience and enjoying
                video games.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </StyledSection>
  )
}

export default About
