import React, { useEffect, useState } from "react"
import { StyledSection } from "./HeaderStylesheet"
import { Container } from "react-bootstrap"
import { Link } from "react-scroll"
import header from "../../videos/header.mp4"
import poster from "../../images/header.jpg"
import mobileheader from "../../videos/mobileheader.mp4"
import mobileposter from "../../images/mobileheader.jpg"

const Header = () => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsMobile(false)
    } else {
      setIsMobile(true)
    }
  }, [])

  return (
    <StyledSection id="header" className="jumbotron">
      {isMobile ? (
        <video
          className="video"
          autoPlay
          loop
          muted
          src={mobileheader}
          poster={mobileposter}
        />
      ) : (
        <video
          className="video"
          autoPlay
          loop
          muted
          src={header}
          poster={poster}
        />
      )}
      <Container>
        <h1
          className="title"
          data-sal={isMobile ? "slide-up" : "slide-right"}
          data-sal-delay="500"
          data-sal-easing="ease"
          data-sal-duration="1000"
        >
          Hi, my name is <span className="name">Yaysian</span>
          <br />I commentate fighting games.
        </h1>
        <p
          className="cta"
          data-sal={isMobile ? "slide-up" : "slide-right"}
          data-sal-delay="1000"
          data-sal-easing="ease"
          data-sal-duration="1000"
        >
          <span className="cta-btn cta-btn--header">
            <Link to="about" smooth duration={1000}>
              Get to know me
            </Link>
          </span>
        </p>
      </Container>
    </StyledSection>
  )
}

export default Header
