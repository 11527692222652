import styled from "styled-components"
import {
  WHITE_COLOR,
  ORANGE_COLOR,
  DARK_ORANGE_COLOR,
} from "../../constants/ColorConstants"
import { TAB_LAND } from "../../constants/BreakpointConstants"

export const StyledSection = styled.section`
  background-color: ${WHITE_COLOR};
  margin-top: -15rem;
  padding-top: 15rem;

  @media (max-width: ${TAB_LAND}) {
    margin-top: 0;
    padding-top: 5rem;
  }

  .text-color-main {
    background-image: linear-gradient(
      135deg,
      ${DARK_ORANGE_COLOR} 0%,
      ${ORANGE_COLOR} 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }  
  
   &:hover {
      transform: translateY(-2px);
    }
  }

  .commentator {
    padding: 0.8rem 0.4rem;
  }

  .event-wrapper {
    margin-bottom: 15rem;

    @media (max-width: ${TAB_LAND}) {
      margin-bottom: 0;
    }

    .row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-bottom: 4rem;

      @media (max-width: ${TAB_LAND}) {
        margin-bottom: 2rem;
      }
    }

    .cta-btn {
      margin-top: .5rem;
    }

    &__text {
      text-align: left;

      &-title {
          font-weight: bold;
          font-size: 2.5rem;
      }

      &-subtitle {
        font-style: italic;
        font-weight: bold;
        margin-top: .5rem;
        margin-bottom: 0;
      }
    }
  }
  }
`
