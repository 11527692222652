import React, { useRef } from "react"
import { StyledSection } from "./GamesStylesheet"
import { Container, Row, Col } from "react-bootstrap"
import GameImage from "../Image/GameImage"
import PropTypes from "prop-types"
import { useSpring, animated } from "react-spring"
import { nanoid } from "nanoid"

const ROW_ONE = [
  {
    filename: "ggst.webp",
    alt: "Guilty Gear -Strive-",
  },
  {
    filename: "umvc3.webp",
    alt: "Ultimate Marvel vs. Capcom 3",
  },
  {
    filename: "mvci.webp",
    alt: "Marvel vs. Capcom Infinite",
  },
  {
    filename: "uniclr.webp",
    alt: "Under Night In-Birth Exe:Late[cl-r]",
  },
]

const ROW_TWO = [
  {
    filename: "gbvs.webp",
    alt: "Granblue Fantasy: Versus",
  },
  {
    filename: "sms.webp",
    alt: "Sailor Moon S",
  },
  {
    filename: "dbfz.webp",
    alt: "Dragon Ball FighterZ",
  },
  {
    filename: "xrd.webp",
    alt: "Guilty Gear Xrd REV 2",
  },
]

const Game = ({ filename, alt }) => {
  const gameRef = useRef(null)
  const calc = (x, y) => [-(y / 2) / 5, x / 2 / 5, 1.1]
  const trans = (x, y, s) =>
    `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`
  const [props, set] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 5, tension: 350, friction: 80 },
  }))

  return (
    <Col md={3}>
      <animated.div
        ref={gameRef}
        onMouseMove={e => {
          set({ xys: calc(e.nativeEvent.offsetX, e.nativeEvent.offsetY) })
        }}
        onMouseLeave={() => set({ xys: [0, 0, 1] })}
        style={{ transform: props.xys.interpolate(trans) }}
      >
        <GameImage filename={filename} alt={alt} key={nanoid()} />
      </animated.div>
    </Col>
  )
}

GameImage.propTypes = {
  filename: PropTypes.string,
  alt: PropTypes.string,
}

const Games = () => {
  return (
    <StyledSection id="games">
      <h2
        className="section-title"
        data-sal="fade"
        data-sal-delay="300"
        data-sal-easing="ease"
        data-sal-duration="1000"
      >
        Games Commentated
      </h2>
      <Container>
        <Row
          data-sal="slide-up"
          data-sal-delay="600"
          data-sal-easing="ease"
          data-sal-duration="1000"
        >
          {ROW_ONE.map(game => (
            <Game filename={game.filename} alt={game.alt} key={nanoid()} />
          ))}
        </Row>
        <Row
          data-sal="slide-up"
          data-sal-delay="900"
          data-sal-easing="ease"
          data-sal-duration="1000"
        >
          {ROW_TWO.map(game => (
            <Game filename={game.filename} alt={game.alt} key={nanoid()} />
          ))}
        </Row>
      </Container>
    </StyledSection>
  )
}

export default Games
