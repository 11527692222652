import React from "react"
import { StyledSection } from "./ContactStylesheet"
import { Container } from "react-bootstrap"
import { OutboundLink } from "gatsby-plugin-google-gtag"

const Contact = () => {
  return (
    <StyledSection>
      <h2
        className="section-title"
        data-sal="fade"
        data-sal-delay="300"
        data-sal-easing="ease"
        data-sal-duration="1000"
      >
        Contact
      </h2>
      <Container>
        <div
          className="contact-wrapper"
          data-sal="slide-up"
          data-sal-delay="800"
          data-sal-easing="ease"
          data-sal-duration="1000"
        >
          <p className="contact-wrapper__text">
            Want to work together? Let's talk!
          </p>
          <OutboundLink
            target="_blank"
            rel="noopener noreferrer"
            className="cta-btn cta-btn--resume"
            href={"mailto:yaysianfgc@gmail.com"}
          >
            Email Me
          </OutboundLink>
        </div>
      </Container>
    </StyledSection>
  )
}

export default Contact
