import React from "react"
import App from "../components/App"
import "bootstrap/dist/css/bootstrap.min.css"
import {
  WHITE_COLOR,
  ORANGE_COLOR,
  DARK_ORANGE_COLOR,
} from "../constants/ColorConstants"
import { createGlobalStyle } from "styled-components"
import { PHONE } from "../constants/BreakpointConstants"

const GlobalStyles = createGlobalStyle`
    html {
      font-size: 62.5%;
      overflow-x: hidden;
    }

    body {
        font-family: 'Noto Sans JP', sans-serif;
        text-align: center;
        overflow-x: hidden;
    }

    section {
      padding: 5rem 0;
    
      @media (max-width: ${PHONE}) {
      border: none;
      padding-left: 1rem;
      padding-right: 1rem;
      }
    }

    .section-title {
      margin: 0 0 4.5rem;
      font-size: 4rem;
      font-weight: 700;
      text-transform: uppercase;
  }

  .cta-btn {
    display: inline-block;
    position: relative;
    padding: 0.8rem 1.6rem;
    font-weight: bold;
    line-height: 1;
    z-index: 1;
    transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.6s;
  
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 0px;
      height: 100%;
      left: 0;
      bottom: 0;
      z-index: -1;
      transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.3s;
    }
  }

  .cta-btn--header {
    background-image: linear-gradient(135deg, ${ORANGE_COLOR} 0%, ${DARK_ORANGE_COLOR} 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(135deg, ${ORANGE_COLOR} 0%, ${DARK_ORANGE_COLOR} 100%);
    border-image-slice: 1;
    cursor: pointer;
  
    &::after {
      background-image: linear-gradient(135deg, ${ORANGE_COLOR} 0%, ${DARK_ORANGE_COLOR} 100%);
    }
  
    &:hover {
      -webkit-text-fill-color: ${WHITE_COLOR};
      text-decoration: none;
  
      &::after {
        width: 100%;
      }
    }
  }
  
  /* Resume Style */
  .cta-btn--resume {
    color: ${WHITE_COLOR};
    border: 2px solid ${WHITE_COLOR};
  
    &::after {
      background: ${WHITE_COLOR};
    }
  
    &:hover {
      color: ${DARK_ORANGE_COLOR};
      text-decoration: none;
  
      &::after {
        width: 100%;
      }
    }
  }

  p,
  a,
  li  {
    font-size: 1.6rem;
  }

  a,
  a:link,
  a:hover,
  a:visited,
  a:active {
    text-decoration: none;
  }

  a:hover {
    transition: all 0.6s ease-in-out;
  }

  .load-hidden {
    visibility: hidden;
  }
`

export default function Home() {
  return (
    <div>
      <GlobalStyles />
      <App />
    </div>
  )
}
